import React, { useEffect, useState } from 'react';
import { apiBaseUrl } from '../../config';
import { Loader } from '../icons/Loader';

function SoftwareSelection({ onSelect }) {
  const [options, setOptions] = useState([]);
  const [selectedSoftwareSlug, setSelectedSoftwareSlug] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSoftwareOptions();
  }, []);

  const fetchSoftwareOptions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiBaseUrl}/get-software-options`);
      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error('Error fetching software options:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (software) => {
    setSelectedSoftwareSlug(software.slug);
    onSelect(software);
  };

  return (
    <div className='mb-8'>
      <label className='block text-md font-medium text-gray-700 mb-2'>
        Select your CRM
      </label>
      {isLoading ? (
        <div className='flex justify-center items-center h-24'>
          <Loader />
          <span className='ml-2'>Loading CRMs...</span>
        </div>
      ) : (
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2'>
          {options.map((option) => (
            <button
              key={option.slug}
              type='button'
              onClick={() => handleSelect(option)}
              className={`flex flex-col items-center justify-center py-3 border rounded-md transition-colors ${
                selectedSoftwareSlug === option.slug
                  ? 'bg-crminputs-dark-blue-10 border-crminputs-dark-blue'
                  : 'bg-white border-gray-300 hover:bg-gray-50'
              }`}
            >
              <img
                src={option.logo_url}
                alt={option.software}
                className='w-8 h-8 mb-2 rounded-full'
              />
              <span className='text-sm font-medium text-gray-900'>
                {option.software}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default SoftwareSelection;
