import React from 'react';
import { useLocation } from 'react-router-dom';

function ToggleSwitch({ schedule, toggleSchedule }) {


  // Dark mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDarkMode = searchParams.get('dark_mode') === 'true';

  return (
    <div className={"flex items-center text-lg pb-4 pt-4 sm:pb-8 " + (isDarkMode ? "text-white" : "text-gray-600")}>
      <span className="mr-2">Monthly</span>
      <label className="switch mx-1">
        <input type="checkbox" checked={schedule === 'yearly'} onChange={toggleSchedule} />
        <span className="slider round"></span>
      </label>
      <span className="ml-2">Annual <span className={"text-sm " + (isDarkMode ? "text-white" : "text-gray-500") + " italic"}> (get 4 months free)</span></span>
    </div>
  );
}

export default ToggleSwitch;