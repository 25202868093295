import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PricingTable from '../components/pricing/PricingTable';
import ToggleSwitch from '../components/pricing/ToggleSwitch';
import CouponBadge from '../components/pricing/CouponBadge';
import TrustedBy from '../components/pricing/TrustedBy';
import { apiBaseUrl } from '../config';
import Header from '../components/header/Header';
import { useLocation } from 'react-router-dom';

function PricingPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isDarkMode = searchParams.get('dark_mode') === 'true';
    const hideBanner = searchParams.get('hide_banner') === 'true';
    const couponCode = searchParams.get('coupon');

    const [pricingData, setPricingData] = useState({});
    const [schedule, setSchedule] = useState('yearly');
    const [isLoading, setIsLoading] = useState(true);
    const [coupon, setCoupon] = useState(null);

    // Ref to store the current AbortController
    const abortControllerRef = useRef(null);

    console.log('Pricing URL:', window.location.href);

    // Fetch coupon data only when the component mounts or couponCode changes
    useEffect(() => {
        fetchCouponData();
    }, [couponCode]);

    // Fetch pricing data whenever the schedule changes
    useEffect(() => {
        fetchPricingData(schedule);

        // Cleanup function to abort previous fetch when schedule changes or component unmounts
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [schedule]);

    const fetchPricingData = async (schedule) => {
        // Set isLoading to true when starting a new fetch
        setIsLoading(true);

        // Abort any ongoing fetch
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for this fetch
        const controller = new AbortController();
        abortControllerRef.current = controller;

        try {
            const response = await fetch(`${apiBaseUrl}/get-pricing?schedule=${schedule}`, { signal: controller.signal });
            const data = await response.json();
            setPricingData(data);
        } catch (error) {
            if (error.name === 'AbortError') {
                // Fetch aborted
                console.log('Fetch aborted');
            } else {
                console.error('Error fetching pricing data:', error);
            }
        } finally {
            // Only set isLoading to false if this fetch wasn't aborted
            if (!controller.signal.aborted) {
                setIsLoading(false);
            }
        }
    };

    const fetchCouponData = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/get-coupon?coupon=${couponCode}`);
            const data = await response.json();
            setCoupon(data);
        } catch (error) {
            console.error('Error fetching coupon data:', error);
        }
    };

    const toggleSchedule = () => {
        // Remove setIsLoading(true) from here
        setSchedule((prev) => (prev === 'monthly' ? 'yearly' : 'monthly'));
    };

    const title = "Pricing - CRM Inputs";
    const description = "Choose the perfect plan for your needs. Flexible pricing options for individuals and teams.";

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                {/* Other meta tags */}
            </Helmet>

            <Header darkMode={isDarkMode} />
            <div className="container mx-auto py-6 px-4 sm:p-8 relative max-w-screen-xl">
                {coupon && !hideBanner && <CouponBadge coupon={coupon} hasUrlCoupon={couponCode !== null} />}
                <h1 className={`text-5xl font-black text-center mt-10 mb-2 ${isDarkMode ? "text-white" : "text-black"}`}>Pricing</h1>

                <div className="flex justify-center mb-2 relative">
                    <ToggleSwitch schedule={schedule} toggleSchedule={toggleSchedule} />
                </div>
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className={`animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 ${isDarkMode ? "border-white" : "border-blue-200"}`}></div>
                    </div>
                ) : (
                    <PricingTable pricingData={pricingData} schedule={schedule} coupon={coupon} />
                )}

                <div className="pt-12">
                    <TrustedBy />
                </div>
            </div>
        </>
    );
}

export default PricingPage;
