const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://api.crminputs.io'
    : 'http://localhost:8080';


const googleClientId = process.env.NODE_ENV === 'production'
    ? '725732425332-7dfknp057l75if54inmvjbkv75ailaai.apps.googleusercontent.com'
    : '725732425332-be6ue37h9edber2kffmtgb12umvocbr9.apps.googleusercontent.com';

const colorDarkBlue = '#003B4F';

export { apiBaseUrl, googleClientId, colorDarkBlue };