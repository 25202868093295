// InstalledPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../config';
import { LoaderDark } from '../components/icons/LoaderDark';

import { GetURLRedirect } from '../utils/Router';
import TrustedBy from '../components/pricing/TrustedBy';
import Logo from '../components/header/Logo';
import SignupProgressBar from '../components/signup/SignupProgressBar';

function InstalledPage() {
  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Set 'has_installed' in localStorage when the page loads
    localStorage.setItem('has_installed', true);
  }, []);

  console.log('Installed URL:', window.location.href);

  useEffect(() => {
    const softwareSlug = localStorage.getItem('software');
    if (softwareSlug) {
      fetchSoftware(softwareSlug);
    } else {
      // If no software in localStorage, redirect to home or error page
      navigate('/signup');
    }
  }, []);

  const fetchSoftware = async (softwareSlug) => {
    setIsLoadingSoftware(true);
    const response = await fetch(`${apiBaseUrl}/get-software?software=${softwareSlug}`);
    const data = await response.json();
    if (data && data.slug === softwareSlug) {
      setSoftware(data);

      setTimeout(() => {
        navigate(GetURLRedirect());
      }, 4000); // Redirect after 4 seconds
    } else {
      console.error('Software not found');
      navigate('/signup');
    }

    setIsLoadingSoftware(false);
  };




  return (
    <div className='sm:min-h-screen sm:flex'>
      {/* Left column */}
      <div className='flex-1 flex flex-col justify-between bg-white'>
        {isLoadingSoftware ? (
          <div className='flex justify-center items-center h-full'>
            <LoaderDark />
          </div>
        ) : (
          <>
            <div className='py-8 sm:py-16 px-8 sm:px-8 md:px-16 lg:px-20 xl:px-24 2xl:px-24'>

              <Logo loading={isLoadingSoftware} software={software} />
              <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                Successfully installed {software.app}
              </h1>
              <p className='mt-4'>You will be redirected shortly...</p>
              <div className="p-32">
                <SignupProgressBar currentStep={1} />
              </div>
            </div>

          </>
        )}

      </div>

      {/* Right column */}
      <div className='flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16'>
        <h3 className='text-white text-4xl font-bold pb-10'>Stop doing CRM data entry.</h3>
        <img
          className='max-w-full sm:max-w-sm object-contain'
          src='/img/signup-hero.jpg'
          alt='CRM Inputs - LinkedIn Integrations for CRMs'
        />
        <h3 className='text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16'>
          1-Click LinkedIn to CRM integration ✨
        </h3>

        <TrustedBy darkMode={true} />
      </div>
    </div>
  );
}

export default InstalledPage;
