 // Import js-cookie
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import DefaultLogo from '../components/header/DefaultLogo';
import TrustedBy from '../components/pricing/TrustedBy';
import { Link } from 'react-router-dom';
import { apiBaseUrl } from '../config';
import Logo from '../components/header/Logo';
import { OAUTH_KEYS } from '../constant';

function LogoutPage() {
    const title = "Logout - CRM Inputs";
    const description = "You have been logged out successfully.";

    const softwareSlug = localStorage.getItem('software'); // Retrieve software from localStorage

    const [software, setSoftware] = useState(null);
    const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);

    console.log('Logout URL:', window.location.href);

    useEffect(() => {
        // Delete the auth_token cookie
        console.log("Logging out");
        // Send POST request to /logout
        fetch(`${apiBaseUrl}/logout`, {
            method: 'POST',
            credentials: 'include',
        });

        OAUTH_KEYS.forEach(key => {
            localStorage.removeItem(key);
        });

        localStorage.removeItem('is_authenticated');

        if (softwareSlug) {
            fetchSoftware(softwareSlug);
        }
    }, [softwareSlug]);

    const fetchSoftware = async (softwareSlug) => {
        setIsLoadingSoftware(true);
        try {
            const response = await fetch(`${apiBaseUrl}/get-software?software=${softwareSlug}`);
            const data = await response.json();
            if (data && data.slug === softwareSlug) {
                setSoftware(data);
            } else {
                console.error('Software not found');
            }
        } catch (error) {
            console.error('Error fetching software:', error);
        } finally {
            setIsLoadingSoftware(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://app.crminputs.io/logout" />
            </Helmet>

            <div className="min-h-screen flex">
                {/* Left column */}
                <div className="flex-1 flex flex-col justify-between bg-white">
                    <div className="py-16 px-8 sm:px-8 md:px-16 lg:px-20 xl:px-24 2xl:px-24">

                        <Logo loading={isLoadingSoftware} software={software} />

                        <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
                            You have been logged out.
                        </h2>
                        <Link to="/login" className='mt-8 inline-block py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed '>Login again</Link>
                    </div>
                </div>

                <div className='flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16'>
                    <h3 className='text-white text-4xl font-bold pb-10'>Stop doing CRM data entry.</h3>
                    <img
                        className='max-w-full sm:max-w-sm object-contain'
                        src='/img/signup-hero.jpg'
                        alt='CRM Inputs - LinkedIn Integrations for CRMs'
                    />
                    <h3 className='text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16'>
                        1-Click LinkedIn to CRM integration ✨
                    </h3>

                    <TrustedBy darkMode={true} />
                </div>
            </div>
        </>
    );
}

export default LogoutPage;
