import React from 'react';
import LinkLogo from './LinkLogo';
import DefaultLogo from './DefaultLogo';
import { LoaderDark } from '../icons/LoaderDark';

const Logo = ({ software, isLoading }) => {
  if (isLoading) {
    return <LoaderDark />;
  }

  if (software) {
    return <LinkLogo app={software.app} software={software.software} />;
  }

  return <DefaultLogo />;
};

export default Logo; 