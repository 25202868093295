import React, { useState, useEffect } from 'react';
import { XCircle } from '../icons/XCircle';

function CouponBadge({ coupon, hasUrlCoupon }) {
  const [timeLeft, setTimeLeft] = useState('');
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const secondsLeft = coupon.redeem_by - now;
      if (secondsLeft <= 0) {
        clearInterval(timer);
        setTimeLeft('Expired');
      } else {
        const days = Math.floor(secondsLeft / 86400);
        const hours = Math.floor((secondsLeft % 86400) / 3600);
        const minutes = Math.floor((secondsLeft % 3600) / 60);
        setTimeLeft(`${days}d ${hours}h ${minutes}m`);
      }
    }, 60000);

    return () => clearInterval(timer);
  }, [coupon]);

  if (!show) return null;

  return (
    <div className="fixed top-0 left-0 w-full bg-cyan-600 text-white py-2 z-50">
      <div className="max-w-7xl mx-auto px-4 flex items-center justify-between">
        <div className="text-center w-full flex justify-center items-center">
          <p className="font-bold text-lg sm:text-xl pr-2">
            {coupon && coupon.percent_off
              ? `${coupon.percent_off}% Discount Applied!`
              : 'Limited Time Offer!'}
          </p>
          <p className="text-sm sm:text-md italic">
            Limited time offer 
            {coupon.duration === 'once'
              ? ' using coupon'
              : ' using coupon'}{' '}
            <span className="font-semibold">"{coupon.name}"</span>
            {/*timeLeft && ` - Expires in ${timeLeft}`*/}
          </p>
        </div>
        {/* Close Button */}
        <button
          type="button"
          onClick={() => setShow(false)}
          className="absolute top-2.5 right-4"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default CouponBadge;
