import React from 'react';
import PlanCard from './PlanCard';

function PricingTable({ pricingData, schedule, coupon }) {
  // Define the order of plans
  const planOrder = ['free', 'standard', 'pro'];

  const convertCentsToDollars = (cents) => {
    return (parseFloat(cents) / 100).toFixed(2);
  };

  const calculateMonthlyPrice = (yearlyPriceInCents) => {
    return convertCentsToDollars(yearlyPriceInCents / 12);
  };

  const applyDiscount = (priceInDollars) => {
    if (!coupon) return priceInDollars;
    const price = parseFloat(priceInDollars);
    if (coupon.percent_off) {
      return (price * (1 - coupon.percent_off / 100)).toFixed(2);
    }
    return Math.max(0, price - coupon.amount_off / 100).toFixed(2);
  };

  return (
    <div className="flex justify-center space-y-12 md:space-y-0 md:space-x-8 pb-16 md:pb-12 flex-col md:flex-row">
      {planOrder.map((plan) => {
        const planData = pricingData[plan];
        if (!planData) return null; // Skip if plan data is not available

        const originalPrice = schedule === 'yearly' 
          ? calculateMonthlyPrice(planData.price)
          : convertCentsToDollars(planData.price);
        const discountedPrice = applyDiscount(originalPrice);

        return (
          <PlanCard 
            key={plan} 
            plan={plan} 
            coupon={coupon}
            data={{
              ...planData,
              originalPrice: originalPrice,
              price: discountedPrice,
              billingPeriod: schedule === 'yearly' ? 'month' : 'month',
              isYearly: schedule === 'yearly'
            }}
          />
        );
      })}
    </div>
  );
}

export default PricingTable;
