import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../../config';
import InputField from '../fields/InputField';
import GoogleLoginButton from '../auth/GoogleLoginButton';

import { GetURLRedirect } from '../../utils/Router';

function LoginForm() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const response = await fetch(`${apiBaseUrl}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include', 
      });

      const data = await response.json();

      if (!response.ok || data.error) {
        throw new Error(data.message || 'Login failed');
      }

      localStorage.setItem('is_authenticated', true);
      console.log("process.env.NODE_ENV", process.env.NODE_ENV);


      navigate(GetURLRedirect());
    } catch (error) {
      console.error('Login error:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };


  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsString = urlParams.toString();

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Display error message if it exists */}
      <div className="">
        <GoogleLoginButton />
      </div>

      <div className="flex items-center w-full mt-8">
        <div className="flex-grow h-px bg-gray-300"></div>
        <p className="mx-4 text-md text-gray-500">Or</p>
        <div className="flex-grow h-px bg-gray-300"></div>
      </div>

      <InputField
        label="Email address"
        id="email"
        name="email"
        type="email"
        placeholder="you@yourcompany.com"
        autoComplete="email"
        required
        value={formData.email}
        onChange={handleChange}
      />
      <InputField
        label="Password"
        id="password"
        name="password"
        type="password"
        autoComplete="new-password"
        required
        value={formData.password}
        onChange={handleChange}
      />
      <div>
        <button
          type="submit"
          className="w-auto flex justify-center py-3 px-6 rounded-md shadow-sm text-md font-bold bg-crminputs-light-blue hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Loading...
            </>
          ) : (
            'Login'
          )}
        </button>

        {errorMessage && (
          <div className="text-red-500 py-8 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}

        <p className="text-md text-gray-500 pt-8">
          Don't have an account?{' '}
          <Link to={`/signup?${urlParamsString}`} className="underline hover:no-underline">
            Sign up
          </Link>
        </p>
      </div>
    </form>
  );
}

export default LoginForm;
