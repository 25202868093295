import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import TrustedBy from '../components/pricing/TrustedBy';

import Logo from '../components/header/Logo';
import { apiBaseUrl } from '../config';

/* 
  When the user logs in, or is signing up but has already installed
  */
function CompletePage() {
  const title = "Complete Installation - CRM Inputs";
  const description = "Finish setting up your CRM Inputs account and start optimizing your CRM workflow.";

  const [countdown, setCountdown] = useState(3);
  const [countdownFinished, setCountdownFinished] = useState(false);

  const linkedinUrl = 'https://www.linkedin.com/in/philmcp/';
  const crmInputsUrl = 'https://crminputs.com/installed?landing_page=true';

  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);


  console.log('Complete URL:', window.location.href);

  useEffect(() => {
    const softwareSlug = localStorage.getItem('software');
    if (softwareSlug) {
      fetchSoftware(softwareSlug);
    }
  }, []);

  const fetchSoftware = async (softwareSlug) => {
    setIsLoadingSoftware(true);
    try {
      const response = await fetch(`${apiBaseUrl}/get-software?software=${softwareSlug}`);
      const data = await response.json();
      if (data && data.slug === softwareSlug) {
        setSoftware(data);
      } else {
        console.error('Software not found');
      }
    } catch (error) {
      console.error('Error fetching software:', error);
    } finally {
      setIsLoadingSoftware(false);
    }
  };

  useEffect(() => {

    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCountdownFinished(true);
      handleRedirect();

    }
  }, [countdown]);

  const handleRedirect = () => {
    window.location.href = linkedinUrl;

  };



  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.crminputs.io/complete-install" />
      </Helmet>

      <div className="min-h-screen flex">
        {/* Left column */}
        <div className="flex-1 flex flex-col justify-between bg-white">
          <div className="py-16 px-8 sm:px-8 md:px-16 lg:px-20 xl:px-24 2xl:px-24">

            <Logo loading={isLoadingSoftware} software={software} />
            <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
              Setup complete.
            </h2>
            <div className="mt-4">
              <p className="text-lg pb-8">
                Redirecting you to LinkedIn so you can start using the Chrome Extension.
              </p>
              <button
                className="w-full flex justify-center py-2.5 px-6 border border-transparent rounded-md shadow-sm text-md font-medium text-black bg-cyan-600 text-white hover:bg-crminputs-darkish-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-crminputs-darkish-blue mb-10"
                onClick={countdownFinished ? handleRedirect : undefined}
              >
                {countdownFinished
                  ? "Click here if you're not redirected"
                  : `Redirecting to LinkedIn in ${countdown}s...`}
              </button>

            </div>
            <div className="p-32">
              <SignupProgressBar currentStep={3} />
            </div>
          </div>

        </div>

        {/* Right column */}
        <div className="flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16">
          <h3 className="text-white text-4xl font-bold pb-10">
            Ready to streamline your CRM?
          </h3>
          <img
            className="max-w-full sm:max-w-sm object-contain"
            src="/img/signup-hero.jpg"
            alt="CRM Inputs - Ready to Start"
          />
          <h3 className="text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16">
            Seamless LinkedIn to CRM integration in one click ✨
          </h3>

          <TrustedBy darkMode={true} />
        </div>
      </div>
    </>
  );
}

export default CompletePage;
