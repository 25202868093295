import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../config';
import { LoaderDark } from '../components/icons/LoaderDark';
import LinkLogo from '../components/header/LinkLogo';
import Logo from '../components/header/Logo';

function InstallPage() {
    const title = "Install Chrome Extension - CRM Inputs";
    const description = "Install our Chrome Extension to enhance your CRM workflow.";

    const currentStep = 1; // This is the second step

    const { state } = useLocation();
    const [countdown, setCountdown] = useState(5);
    const [countdownFinished, setCountdownFinished] = useState(false);
    const [software, setSoftware] = useState(null);
    const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);
    const navigate = useNavigate();

    console.log('Install URL:', window.location.href);

    useEffect(() => {
        const softwareSlug = localStorage.getItem('software');
        if (softwareSlug) {
            fetchSoftware(softwareSlug);
        } else {
            console.log('No software in localStorage, redirecting to signup');
            navigate("/signup")
        }
    }, []);


    const fetchSoftware = async (softwareSlug) => {
        setIsLoadingSoftware(true);
        const response = await fetch(`${apiBaseUrl}/get-software?software=${softwareSlug}`);
        const data = await response.json();
        if (data && data.slug === softwareSlug) {
            setSoftware(data);
        } else {
            console.error('Software not found');
        }
        setIsLoadingSoftware(false);
    };


    useEffect(() => {

        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setCountdownFinished(true);
            console.log('Redirecting to Chrome Extension');
            window.location.href = software.chrome_extension_url;
        }
    }, [countdown]);

    const handleManualRedirect = () => {
        window.location.href = state.chrome_extension_url;
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://app.crminputs.io/install" />
            </Helmet>

            <div className="min-h-screen flex">
                {/* Left column */}
                <div className="flex-1 flex flex-col justify-between bg-white">
                    <div className="py-8 sm:py-16 px-8 sm:px-8 md:px-16 lg:px-20 xl:px-24 2xl:px-24">
                        <Logo loading={isLoadingSoftware} software={software} />
                        <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
                            Now let's install the Chrome Extension.
                        </h2>
                        <div className="mt-8">
                            <p className="text-xl mb-10">
                                We're redirecting you to the Chrome Web Store to install the extension - please wait...
                            </p>
                            <button
                                className="w-full flex justify-center py-2.5 px-6 border border-transparent rounded-md shadow-sm text-md font-medium text-black bg-cyan-600 text-white hover:bg-crminputs-darkish-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-crminputs-darkish-blue mb-10"
                                onClick={handleManualRedirect}
                            >
                                {countdownFinished
                                    ? "Click here if you're not redirected"
                                    : `Redirecting in ${countdown} seconds...`}
                            </button>

                            <p className="text-sm text-gray-500">If you're not redirected, please click the button above.</p>
                        </div>
                    </div>
                    <div className="p-32">
                        <SignupProgressBar currentStep={currentStep} />
                    </div>
                </div>

                {/* Right column */}
                <div className="flex-1 flex items-center justify-center bg-crminputs-dark-blue flex-col">
                    <h3 className="text-white text-4xl font-bold pb-10">
                        Stop doing CRM data entry.
                    </h3>
                    <img
                        className="max-w-full sm:max-w-sm object-contain"
                        src="/img/signup-hero.jpg"
                        alt="CRM Inputs - Chrome Extension Installation"
                    />
                    <h3 className="text-cyan-200 italic text-2xl font-default pt-10">
                        1-Click LinkedIn to CRM integration ✨
                    </h3>
                </div>
            </div>
        </>
    );
}

export default InstallPage;
