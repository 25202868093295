export const GetURLRedirect = () => {

    let hasInstalled = localStorage.getItem('has_installed') == 'true';
    let isAuthenticated = localStorage.getItem('is_authenticated') == 'true';
    let hasConnected = localStorage.getItem('oauth_access_token') != null;
    let isOauth = localStorage.getItem('is_oauth') == 'true';


    let urlParams = new URLSearchParams(window.location.search);
    let urlParamsString = urlParams.toString();

    console.log("Redirecting (", urlParamsString, ") ", "hasInstalled", hasInstalled,  "hasConnected", hasConnected, "isOauth", isOauth);

    if (urlParamsString != "") {
        urlParamsString = "?" + urlParamsString;
    }


    // Has not completed the signup flow at some point
    if (isAuthenticated) {
        // Is logged in
        if (hasInstalled) {
            // Has installed
            if (isOauth && !hasConnected) {
                // Is oauth
                return '/connect' + urlParamsString;
            } else if (isOauth && hasConnected) {
                let fragUrl = new URLSearchParams();

                // Iterate over all keys in localStorage that start with 'oauth_'
                Object.keys(localStorage).forEach(key => {
                    if (key.startsWith('oauth_')) {
                        fragUrl.set(key, localStorage.getItem(key));
                    }
                });

                return '/connected#' + fragUrl.toString();
            } else {
                console.log(2222)
                // Is not aut
                return '/complete' + urlParamsString;
            }
        } else {
            return '/install' + urlParamsString;
        }
    } else {
        // Is not logged in
        return '/signup' + urlParamsString;
    }



}