import React from 'react';
import { colorDarkBlue } from '../../config';
import { useLocation } from 'react-router-dom';
// Image file names
const imageFiles = ['user1.jpg', 'user2.jpg', 'user3.jpg', 'user4.jpg'];

const TrustedBy = ({ darkMode }) => {



  // Dark mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDarkMode = darkMode || searchParams.get('dark_mode') === 'true';

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex justify-center -space-x-4">
        {imageFiles.map((file, index) => (
          <div key={index} className="flex justify-center">
            <img
              decoding="async"
              src={`/img/trustedby/${file}`}
              alt={`User ${index + 1}`}
              loading="lazy"
              className={"w-12 h-12 object-cover rounded-full border-4 " + (isDarkMode ? "border-crminputs-dark-blue" : "border-white")}

            />
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center">
        <h2 className={"text-md font-medium text-center " + (isDarkMode ? "text-white" : "text-gray-600")}>
          Trusted by thousands of SDRs, BDRs, Sales Ops, Team Leads & more
        </h2>
        <div className="flex justify-center mt-2">
          {[...Array(5)].map((_, i) => (
            <StarIcon key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

const StarIcon = () => (
  <svg
    aria-hidden="true"
    fill="gold"
    className="w-5 h-5 text-yellow-500"
    viewBox="0 0 1000 1000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M450 75L338 312 88 350C46 354 25 417 58 450L238 633 196 896C188 942 238 975 275 954L500 837 725 954C767 975 813 942 804 896L763 633 942 450C975 417 954 358 913 350L663 312 550 75C529 33 471 33 450 75Z"></path>
  </svg>
);

export default TrustedBy;
