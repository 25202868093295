import React from 'react';
import PropTypes from 'prop-types';

const SignupProgressBar = ({ currentStep }) => {
  const steps = ['Signup', 'Install', 'Connect', 'Finish'];
  return (
    <ol className="flex items-center w-full space-x-2 text-sm font-medium text-center text-gray-400  sm:text-base   sm:space-x-4 rtl:space-x-reverse justify-center">
      {steps.map((step, index) => (
        <li key={index} className={`flex items-center ${index === currentStep ? 'text-crminputs-dark-blue ' : ''}`}>
          <span className={`flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0 ${index === currentStep ? 'border-crminputs-dark-blue ' : 'border-gray-400 '
            }`}>
            {index + 1}
          </span>
          {step}
          {index < steps.length - 1 && (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 ml-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>

          )}
        </li>
      ))}
    </ol>
  );
};

SignupProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default SignupProgressBar;
