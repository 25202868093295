import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetURLRedirect } from '../utils/Router';
import { OAUTH_KEYS } from '../constant';

function AuthPage() {
    const navigate = useNavigate();

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let fragmentParams = new URLSearchParams(window.location.hash.substring(1));
        const installed = urlParams.get('installed') === 'true';
        const reauth = urlParams.get('reauth') === 'true';
        const softwareParam1 = urlParams.get('software');
        const softwareParam2 = fragmentParams.get('software');
        const softwareParam = softwareParam1 || softwareParam2;

        // Print full url
        console.log('Auth URL:', window.location.href);

        // Normal params
        if (softwareParam) {
            localStorage.setItem('software', softwareParam);
        }

        // Fragment params
        OAUTH_KEYS.forEach(key => {
            if (fragmentParams.get(key)) {
                localStorage.setItem(key, fragmentParams.get(key));
            }
        });


        if (installed) {
            localStorage.setItem('has_installed', true);
        }

        if (reauth) {
            console.log('Reauth, redirecting to login');
            localStorage.removeItem('is_authenticated');
            navigate('/login?' + urlParams.toString());
        }

        let redirectUrl = GetURLRedirect();

        console.log('redirectUrl', redirectUrl);
        navigate(redirectUrl);

    }, [navigate]);


    // Return empty div while redirect happens
    return <div></div>;
}

export default AuthPage;
