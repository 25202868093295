import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OAUTH_KEYS } from '../constant';

function CallbackPage() {
    const navigate = useNavigate();

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let fragmentParams = new URLSearchParams(window.location.hash.substring(1));
        const isAuthenticated = localStorage.getItem('is_authenticated') === 'true';
        const didHandshake = localStorage.getItem('oauth_access_token') !== null;

        // Print full url
        console.log('Callback URL:', window.location.href);

        // Save all fragment params to localStorage
        let fragString = '';
        [...OAUTH_KEYS, 'software'].forEach(key => {
            if (fragmentParams.get(key)) {
                localStorage.setItem(key, fragmentParams.get(key));
            }
            if(localStorage.getItem(key)) {
                fragString += key + '=' + localStorage.getItem(key) + '&';
            }
        });

        // OAuth frag string
        if (isAuthenticated) {
            if (didHandshake) {
                navigate('/connected#' + fragString);
            } else {
                navigate('/connect');
            }
        } else {
            navigate('/auth');
        }

    }, [navigate]);


    // Return empty div while redirect happens
    return <div></div>;
}

export default CallbackPage;