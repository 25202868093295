import React from 'react';

function InputField({ label, id, name, type, autoComplete, required, value, onChange, placeholder }) {
  return (
    <div>
      <label htmlFor={id} className="block text-lg font-default text-gray-800">
        {label}
      </label>
      <div className="mt-1.5">
        <input
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="appearance-none block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-crminputs-dark-blue  focus:border-crminputs-darkish-blue sm:text-sm"
        />
      </div>
    </div>
  );
}

export default InputField;