import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../../config';

import { GetURLRedirect } from '../../utils/Router';
function GoogleSignupButton({ software, onSubmit }) {
  const navigate = useNavigate();

  const handleLoginSuccess = async (credentialResponse) => {
    console.log('Signup Success:', credentialResponse);

    try {
      onSubmit();
      // Get UTM parameters from localStorage
      const utmParams = {
        utm_source: localStorage.getItem('utm_source') || '',
        utm_medium: localStorage.getItem('utm_medium') || '',
        utm_campaign: localStorage.getItem('utm_campaign') || '',
        utm_term: localStorage.getItem('utm_term') || '',
        utm_content: localStorage.getItem('utm_content') || '',
        type: "signup",
        token: credentialResponse.credential,
        software: software.slug, // Should be software name, not slug
      };

      // Combine formData with utmParams
      const requestData = {
        ...utmParams,
        token: credentialResponse.credential,
      };



      const res = await fetch(`${apiBaseUrl}/google-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include', 
      });

      const data = await res.json();

      if (res.ok) {
        console.log("process.env.NODE_ENV", process.env.NODE_ENV);

   /*    Cookies.set('auth_token', data.auth_token, {
          
          expires: 90, // Expires in 90 days
          secure: process.env.NODE_ENV === 'production', // Cookie will only be sent over HTTPS in production
          sameSite: process.env.NODE_ENV === 'production' ? 'None' : 'Lax', // Use 'Lax' for local development
          path: '/', // Cookie is accessible on all pages
          domain: process.env.NODE_ENV === 'production' ? '.crminputs.io' : 'localhost', // Use domain for production, localhost for development
        });*/

        localStorage.setItem('is_authenticated', true);

        navigate(GetURLRedirect());

      } else {
        console.error('Google signup failed:', data.message);
      }
    } catch (error) {
      console.error('An error occurred during Google signup:', error);
    }
  };

  const handleLoginError = (error) => {
    console.error('Login Failed:', error);
  };

  return (
    <div style={{ position: 'relative', width: 'fit-content' }}>
      <label className='block text-lg font-default text-gray-800 mb-2'>Sign up with Google</label>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={handleLoginError}
        useOneTap={false}
        text='signup_with'
        size='large'
        width={'100%'}
      />
    </div>
  );
}

export default GoogleSignupButton;
