// SignupPage.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import Logo from '../components/header/Logo';
import { LoaderDark } from '../components/icons/LoaderDark';
import TrustedBy from '../components/pricing/TrustedBy';
import SignupForm from '../components/signup/SignupForm';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import { apiBaseUrl } from '../config';
// Import js-cookie

function SignupPage() {
  const title = 'Sign Up - CRM Inputs';
  const description = 'Create your account and start optimizing your CRM workflow today.';
  const [searchParams] = useSearchParams();

  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);

  useEffect(() => {
    localStorage.removeItem('is_authenticated');

    const softwareSlug = searchParams.get('software');
    if (softwareSlug) {
      localStorage.setItem('software', softwareSlug); // Save software slug to localStorage
      fetchSoftware(softwareSlug);
    }
  }, [searchParams]);

  console.log('Signup URL:', window.location.href);


  const fetchSoftware = async (softwareSlug) => {
    setIsLoadingSoftware(true);
    const response = await fetch(`${apiBaseUrl}/get-software?software=${softwareSlug}`);
    const data = await response.json();
    if (data && data.slug === softwareSlug) {
      setSoftware(data);
      localStorage.setItem('is_oauth', data.is_oauth == true);
    } else {
      console.error('Software not found');
    }

    setIsLoadingSoftware(false);
  };



  let urlParams = new URLSearchParams(window.location.search);
  const installed = urlParams.get('installed') === 'true';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://app.crminputs.io/signup' />
      </Helmet>


      <div className='sm:min-h-screen sm:flex'>
        {/* Left column */}
        <div className='flex-1 flex flex-col justify-between bg-white'>
          {isLoadingSoftware ? (
            <div className='flex justify-center items-center h-full'>
              <LoaderDark />
            </div>
          ) : (
            <>
              <div className='py-8 sm:py-16 px-8 sm:px-8 md:px-16 lg:px-20 xl:px-24 2xl:px-24'>
                <Logo loading={isLoadingSoftware} software={software} />
                <h2 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                  {installed ? 'Let\'s get you signed up.' : 'Let\'s get you signed up first.'}
                </h2>
                <div className='mt-8'>
                  <SignupForm software={software} />
                </div>
                <div className='pt-32'>
                  <SignupProgressBar
                    currentStep={0} // Always step 1 on the signup page
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Right column */}
        <div className='flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16'>
          <h3 className='text-white text-4xl font-bold pb-10'>Stop doing CRM data entry.</h3>
          <img
            className='max-w-full sm:max-w-sm object-contain'
            src='/img/signup-hero.jpg'
            alt='CRM Inputs - LinkedIn Integrations for CRMs'
          />
          <h3 className='text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16'>
            1-Click LinkedIn to CRM integration ✨
          </h3>

          <TrustedBy darkMode={true} />
        </div>
      </div>
    </>
  );
}

export default SignupPage;
