import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Layout({ children }) {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isDarkMode = searchParams.get('dark_mode') === 'true';
    document.documentElement.classList.toggle('dark', isDarkMode);
  }, [location]);

  return (
    <div className="min-h-screen bg-white dark:bg-crminputs-dark-blue">
      {children}
    </div>
  );
}

export default Layout;