// SignupForm.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import InputField from '../fields/InputField';
import SoftwareSelection from './SoftwareSelection';
import GoogleSignupButton from '../auth/GoogleSignupButton';
import posthog from 'posthog-js';
import { apiBaseUrl } from '../../config';
import { Loader } from '../icons/Loader';
import { GetURLRedirect } from '../../utils/Router';
function SignupForm({ software }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    software: software ? software.software : '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedSoftware, setSelectedSoftware] = useState(software);

  useEffect(() => {
    // Initialize formData from URL parameters for name, email, password
    const nameParam = searchParams.get('name');
    const emailParam = searchParams.get('email');
    const passwordParam = searchParams.get('password');
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...(nameParam ? { name: nameParam } : {}),
      ...(emailParam ? { email: emailParam } : {}),
      ...(passwordParam ? { password: passwordParam } : {}),
    }));
  }, [searchParams]);

  useEffect(() => {
    if (selectedSoftware) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        software: selectedSoftware.software,
      }));
      localStorage.setItem('software', selectedSoftware.slug); // Save software slug to localStorage
    }
  }, [selectedSoftware]);

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSoftwareSelection = (software) => {
    setSelectedSoftware(software);
    setFormData((prevFormData) => ({
      ...prevFormData,
      software: software.software,
    }));
    localStorage.setItem('software', software.slug); // Save software slug to localStorage
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      // Get UTM parameters from localStorage
      const utmParams = {
        utm_source: localStorage.getItem('utm_source') || '',
        utm_medium: localStorage.getItem('utm_medium') || '',
        utm_campaign: localStorage.getItem('utm_campaign') || '',
        utm_term: localStorage.getItem('utm_term') || '',
        utm_content: localStorage.getItem('utm_content') || '',
      };

      // Combine formData with utmParams
      const requestData = {
        ...formData,
        ...utmParams,
      };

      requestData.software = selectedSoftware.slug;

      console.log('requestData333', requestData);

      const response = await fetch(`${apiBaseUrl}/signup`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();

      if (!response.ok || data.error) {
        throw new Error(data.message || 'Signup failed');
      }

      // Save auth_token to localStorage
      console.log("process.env.NODE_ENV", process.env.NODE_ENV);

      localStorage.setItem('is_authenticated', true);
    /*  Cookies.set('auth_token', data.auth_token, {
        expires: 90, // Expires in 90 days
        secure: process.env.NODE_ENV === 'production', // Cookie will only be sent over HTTPS in production
        sameSite: process.env.NODE_ENV === 'production' ? 'None' : 'Lax', // Use 'Lax' for local development
        path: '/', // Cookie is accessible on all pages
        domain: process.env.NODE_ENV === 'production' ? '.crminputs.io' : 'localhost', // Use domain for production, localhost for development
      });*/

      localStorage.setItem('software', selectedSoftware.slug);

      posthog.register({
        distinct_id: data.id,
      });

      navigate(GetURLRedirect());
    } catch (error) {
      console.error('Signup error:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showLoading = () => {
    setIsSubmitting(true);
  };

  // Get the URL params as a string
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsString = urlParams.toString();

  return (
    <form onSubmit={handleSubmit} className='space-y-5'>
      {!selectedSoftware && (
        <SoftwareSelection onSelect={handleSoftwareSelection} />
      )}

      {selectedSoftware && (
        <>
          <div>
            <GoogleSignupButton
              software={selectedSoftware}
              onSubmit={showLoading}
            />
          </div>

          <div className='flex items-center w-full mt-8'>
            <div className='flex-grow h-px bg-gray-300'></div>
            <p className='mx-4 text-md text-gray-500'>Or</p>
            <div className='flex-grow h-px bg-gray-300'></div>
          </div>

          <InputField
            label='Your name'
            id='name'
            name='name'
            type='text'
            placeholder='John Doe'
            autoComplete='name'
            required
            value={formData.name}
            onChange={handleChange}
          />

          <InputField
            label='Email address'
            id='email'
            name='email'
            type='email'
            placeholder='you@yourcompany.com'
            autoComplete='email'
            required
            value={formData.email}
            onChange={handleChange}
          />
          <InputField
            label='Password'
            id='password'
            name='password'
            type='password'
            autoComplete='new-password'
            required
            minLength={7}
            value={formData.password}
            onChange={handleChange}
          />
          <p className='text-sm text-gray-500'>
            By signing up, you agree to our{' '}
            <a href='https://crminputs.com/terms' target='_blank' className='underline hover:no-underline' rel='noreferrer'>
              terms
            </a>{' '}
            and{' '}
            <a href='https://crminputs.com/privacy' target='_blank' className='underline hover:no-underline' rel='noreferrer'>
              privacy policy
            </a>
            .
          </p>

          <div>
            <button
              type='submit'
              className='w-auto flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed'
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Loader />
                  Loading...
                </>
              ) : (
                'Next'
              )}
            </button>

            {errorMessage && (
              <div className='text-red-500 py-8 rounded relative' role='alert'>
                <strong className='font-bold'>Error: </strong>
                <span className='block sm:inline'>{errorMessage}</span>
              </div>
            )}

            <p className='text-md text-gray-500 pt-8'>
              Already have an account?{' '}
              <Link
                to={`/login?${urlParamsString}`}
                className='underline hover:no-underline'
              >
                Login
              </Link>
            </p>
          </div>
        </>
      )}
    </form>
  );
}

export default SignupForm;
